$(document).ready(function() {
  // your code
  var window = $(window);

  $('#nav-icon2').click(function(){
	
    var target = $(this).data('alvo');
    

    if (!$(this).hasClass('open')){
      $(".arrow").addClass('d-none');
    $(target).animate({
      left:'30px',
    },function(){
          $("#nav-icon2").toggleClass('abrir');	
        })
    }
    else{
      $(target).animate({
      left:'-250px',
    },function(){
          $("#nav-icon2").toggleClass('abrir');	
          $(".arrow").removeClass('d-none');
        })
    }
    $(this).toggleClass('open');
  });


  $("body").on('click','.btnClose',function(e){
    e.preventDefault();
    $(".modalSuccess").removeClass('active')
    })
    var window = $(window);
    $(".formSubmit").submit(function(e) {
      $(".formSubmit .btnSubmit").attr('disabled',true)
              e.preventDefault();
              $(".loading").addClass('active');
              var url = "../../enviar.php"; // the script where you handle the form input.
              if(
                $(this).find('input[name="nome"]').val() == "" && 
                $(this).find('input[name="telefone"]').val() == "" &&  
                $(this).find('input[name="email"]').val() == ""){
                  alert("Atenção, Todos os campos são obrigatórios.")
              }
              $.ajax({
                     type: "POST",
                     url: url,
                     data: $(this).serialize(), // serializes the form's elements.
                     success: function(data)
                     {
                       console.log(data)
                        if(data == "enviado"){
                  $(".modalSuccess").addClass('active')
                  $(".formSubmit")[0].reset()
                  $(".loading").removeClass('active');
                        }
                        $(".formSubmit .btnSubmit").attr('disabled',false)
                     },error:function(data){
                console.log(data)
                $(".loading").removeClass('active');
       }
                   });
  
              e.preventDefault(); // avoid to execute the actual submit of the form.
          });
})